import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeHero from "../components/home-components/homeHero"
import StickyNav from "../components/home-components/stickyNav"
import ProductIntroFlexBadge from "../components/home-components/ProductIntroFlexBadge"
import ProductSealLeaks from "../components/home-components/ProductSealLeaks"
import ProductFindSealLeaks from "../components/home-components/ProductFindSealLeaks"
import ProductEnhancePerformance from "../components/home-components/ProductEnhancePerformance"
import ProductRefridgerantRetrofit from "../components/home-components/ProductRefridgerantRetrofit"
import HowToUse from "../components/home-components/HowToUse"
import ProductCoilClearners from "../components/home-components/ProductCoilClearners"
import ProductIntroCoilBadge from "../components/home-components/ProductIntroCoilBadge"
import CalloutSalesSheet from "../components/home-components/CalloutSalesSheet"
import 'smoothscroll-anchor-polyfill';


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HomeHero />
    <StickyNav />
    <ProductIntroFlexBadge />
    <ProductSealLeaks />
    <ProductFindSealLeaks />
    <ProductEnhancePerformance />
    <ProductRefridgerantRetrofit />
      <HowToUse />
      <ProductIntroCoilBadge />
      <ProductCoilClearners />
    <CalloutSalesSheet />
  </Layout>
)

export default IndexPage
