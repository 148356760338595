import React from "react"
import Card from "./card"
import productImage1 from '../../images/product/coilseries.png-1.png'
import productImage2 from '../../images/product/coilseries.png-2.png'
import productImage3 from '../../images/product/coilseries.png-3.png'
import productImage4 from '../../images/product/coilpro.png-1.png'
import productImage5 from '../../images/product/coilpro.png-2.png'
import productImage6 from '../../images/product/coilpro.png-3.png'
import productImage7 from '../../images/product/trip4x.png'
import productImage8 from '../../images/product/progrn4x.png'
import productImage9 from '../../images/product/probl4x.png'
import productImage10 from '../../images/product/prord4x.png'
import productImage1B from '../../images/product/MasterCareLogo.svg'
import productImage11 from '../../images/product/coilshot-1.png'
import productImage12 from '../../images/product/coilshot-2.png'
import productImage13 from '../../images/product/coilshot-3.png'
import productImage14 from '../../images/product/coilshot-4.png'
import productImage15 from '../../images/product/TRIPLE-D-AER_360image_01_front.jpg'
import productImage16 from '../../images/product/PRO-GREEN-AER_360image_01_front.jpg'
import productImage17 from '../../images/product/PRO-BLUE-AER_360image_01_front.jpg'
import PDF1 from '../../pdfs/Triple-D (Jun22).pdf'
import PDF2 from '../../pdfs/Pro-Blue (Jan22).pdf'
import PDF3 from '../../pdfs/Pro-Green (Mar21).pdf'
import PDF4 from '../../pdfs/Pro-Red+ (Jan22).pdf'
import PDF5 from '../../pdfs/Pro-Brown (Jan22).pdf'
import PDF6 from '../../pdfs/Pro-Yellow (Jan22).pdf'
import PDF7 from '../../pdfs/Triple-D-Aer (Nov21).pdf'
import PDF8 from '../../pdfs/Pro-Green-Aer (Nov21).pdf'
import PDF9 from '../../pdfs/Pro-Blue-Aer (Nov21).pdf'
import PDF10 from '../../pdfs/4x Pro-Green (Jun21).pdf'
import PDF11 from '../../pdfs/4x Pro-Blue (May21).pdf'

const ProductCoilClearners = () => (
    <>
        <div id="section-6" className="anchor" />
        <section className="darkgray bkg-repeat-dark">
            <div className="container py-5">
                <div className="row py-5">
                    <div className="col text-center white-text">
                        <h2 className="icon icon4">Coil Cleaners</h2>
                        <p className="mx-auto"
                           style={{
                               maxWidth: '470px'
                           }}>All MasterCare<sup>&reg;</sup> coil cleaners are formulated to be powerful and effective, yet safe for the application they are designed for.</p>
                    </div>
                </div >
                <div className="row justify-content-center">
                    <Card
                        imgSrc={productImage1}
                        cardTitleFront={"TRIPLE -D"}
                        cardTextFront={"MEDIUM DUTY UNIVERSAL COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"1 GALLON"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF1}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage2}
                        cardTitleFront={"PRO-GREEN"}
                        cardTextFront={"LIGHT DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"1 GALLON"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF3}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage3}
                        cardTitleFront={"PRO-BLUE"}
                        cardTextFront={"HEAVY DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"1 GALLON"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF2}
                        cardFooter={""} />

                </div>
            </div>
        </section>
        <section className="black bkg-repeat bkg-repeat-light">
            <div className="container py-5">
                <div className="row py-5">
                    {/*<div className="col text-center white-text">*/}
                    {/*    <h2 className="icon icon4">Coil Cleaners</h2>*/}
                    {/*    <p className="mx-auto"*/}
                    {/*       style={{*/}
                    {/*           maxWidth: '470px'*/}
                    {/*       }}>All MasterCare<sup>&reg;</sup> coil cleaners are formulated to be powerful and effective, yet safe for the application they are designed for.</p>*/}
                    {/*</div>*/}
                </div >
                <div className="row justify-content-center">
                    <Card
                        imgSrc={productImage4}
                        cardTitleFront={"PRO-RED+"}
                        cardTextFront={"EXTRA HEAVY DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"1 GALLON"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF4}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage5}
                        cardTitleFront={"PRO-BROWN"}
                        cardTextFront={"SUPER HEAVY DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"1 GALLON"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF5}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage6}
                        cardTitleFront={"PRO-YELLOW"}
                        cardTextFront={"ULTRA HEAVY DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"1 GALLON"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF6}
                        cardFooter={""} />

                </div>
            </div>
        </section>
        <section className="darkgray bkg-repeat-dark">
            <div className="container py-5">
                <div className="row py-5">
                    <div className="col text-center white-text">
                        <h2 className="icon icon4">CARRY LESS. CLEAN MORE!</h2>
                        <p className="mx-auto"
                           style={{
                               maxWidth: '470px'
                           }}>MasterCare<sup>&reg;</sup> super concentrated coil cleaners allow for more cleanings per ounce than anything else on the market.</p>
                    </div>
                </div >
                <div className="row justify-content-center">
                    <Card
                        imgSrc={productImage7}
                        cardTitleFront={"TRIPLE-D-4X"}
                        cardTextFront={"MEDIUM DUTY UNIVERSAL COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"1 QUART"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF1}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage8}
                        cardTitleFront={"PRO-GREEN-4X"}
                        cardTextFront={"LIGHT DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"1 QUART"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF10}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage9}
                        cardTitleFront={"PRO-BLUE-4X"}
                        cardTextFront={"HEAVY DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"1 QUART"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF11}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage10}
                        cardTitleFront={"PRO-RED-4X"}
                        cardTextFront={"EXTRA HEAVY DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"1 QUART"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF4}
                        cardFooter={""} />

                </div>
            </div>
        </section>
        <section className="black bkg-repeat bkg-repeat-light">
            <div className="container py-5">
                <div className="row py-5">
                    <div className="col text-center white-text">
                        <h2 className="icon icon4">NO MEASURING. NO MESS.</h2>
                        <p className="mx-auto"
                           style={{
                               maxWidth: '470px'
                           }}>1 Shot by MasterCare<sup>&reg;</sup> is a 4oz bottle of pour-and-clean coil cleaner that comes in a convenient countertop display containing 14 bottles. Mix one bottle with one gallon of water.</p>
                    </div>
                </div >
                <div className="row justify-content-center">
                    <Card
                        imgSrc={productImage11}
                        cardTitleFront={"TRIPLE-D-1SHOT"}
                        cardTextFront={"MEDIUM DUTY UNIVERSAL COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"4 OZ"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF1}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage12}
                        cardTitleFront={"PRO-GREEN-1SHOT"}
                        cardTextFront={"LIGHT DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"4 OZ"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF10}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage13}
                        cardTitleFront={"PRO-BLUE-1SHOT"}
                        cardTextFront={"HEAVY DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"4 OZ"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF11}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage14}
                        cardTitleFront={"PRO-RED-1SHOT"}
                        cardTextFront={"EXTRA HEAVY DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"4 OZ"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardFooter={""} />

                </div>
            </div>
        </section>
        <section className="darkgray bkg-repeat-dark">
            <div className="container py-5">
                <div className="row py-5">
                    <div className="col text-center white-text">
                        <h2 className="icon icon4">SPRAY AND RINSE</h2>
                        <p className="mx-auto"
                           style={{
                               maxWidth: '470px'
                           }}>The quickest and easiest way to get the coils cleaned is with MasterCare<sup>&reg;</sup> aerosol coil cleaners. No tanks, no mixing, no equipment.</p>
                    </div>
                </div >
                <div className="row justify-content-center">
                    <Card
                        imgSrc={productImage15}
                        cardTitleFront={"TRIPLE-D-AER"}
                        cardTextFront={"MEDIUM DUTY UNIVERSAL COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"19 OZ"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF7}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage16}
                        cardTitleFront={"PRO-GREEN-AER"}
                        cardTextFront={"LIGHT DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"19 OZ"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF8}
                        cardFooter={""} />
                    <Card
                        imgSrc={productImage17}
                        cardTitleFront={"PRO-BLUE-AER"}
                        cardTextFront={"HEAVY DUTY COIL CLEANER"}
                        imgSrcBack={productImage1B}
                        cardTextBack={"19 OZ"}
                        cardTitleBack={"SAFETY DATA SHEET"}
                        cardButtonURL={PDF9}
                        cardFooter={""} />

                </div>
            </div>
        </section>
    </>
)

export default ProductCoilClearners