import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"


const HeroImageBleed = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "MasterCare_Family_Shot.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="position-absolute w-100 overflow-hidden d-none d-xl-block"
      style={{
        height: 'calc(-131px + 100vh)',
      }}>
      <Img fluid={data.placeholderImage.childImageSharp.fluid}
        objectFit="contain"
        objectPosition="right center"
        style={{
          width: '60%',
          height: '90%',
          top: '50%',
          left: '40%',
          transform: 'translate(0, -50%)'
        }} />
    </div>
  )
}

export default HeroImageBleed
