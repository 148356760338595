import React from 'react'
import Card from './card'
import productImage1 from '../../images/product/991.png'
import productImage1B from '../../images/product/991-MC.png'
import PDF from '../../pdfs/SDS_Mastercare_991-MC_Super_Change.pdf'

const ProductRefridgerantRetrofit = () => (
  <>
    <div id="section-4" className="anchor" />
    <section className="darkgray bkg-repeat-dark">
      <div className="container py-5">
        <div className="row py-5">
          <div className="col text-center white-text">
            <h2 className="icon icon4">Refrigerant Retrofit</h2>
            <p className="mx-auto"
              style={{
                maxWidth: '470px'
              }}>Save time on system conversions with Super Change<sup>&reg;</sup> retrofit that won’t plug TXVs or cap tubes or pistons. Compatible with units up to 12 Tons.</p>
          </div>
        </div >
        <div className="row justify-content-center">
          <Card
            imgSrc={productImage1}
            cardTitleFront={"SUPER CHANGE"}
            cardTextFront={"REFRIGERANT RETROFIT"}
            imgSrcBack={productImage1B}
            cardTextBack={"SUPER CHANGE"}
            cardTitleBack={"SAFETY DATA SHEET"}
            cardButtonURL={PDF}
            cardFooter={"991-MC"} />

        </div>
      </div>
    </section>
  </>
)

export default ProductRefridgerantRetrofit