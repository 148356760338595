import React from 'react'
// import Video from './video'
import Step1Image from './step1Image'
import Step2Image from './step2Image'
import Step5Image from './step5Image'
import Step6Image from './step6Image'
import Step7Image from './step7Image'
import Step10Image from './step10Image'
import Step11Image from './step11Image'

const HowToUse = () => (
  <section className="lightgray">
    <div className="container max py-5">
      <div className="row py-5">
        <div className="col-10 offset-1 col-lg-5 offset-lg-0 mb-5">
          <h2 className="mx-md-auto text-center text-md-left mt-lg-5">HOW TO USE</h2>
          <div className="row mb-5 pt-4 pb-3">
            <div className="col-12">
              <div className="d-block d-lg-none">
                <Step1Image />
              </div>
              <blockquote>
                <em>1</em>Unscrew the red fitting from the blue fitting.
                </blockquote>
            </div>
            <div className="col-12">
              <div className="d-block d-lg-none">
                <Step2Image />
              </div>
              <blockquote>
                <em>2</em>Thread and hand tighten the red fitting onto one end of single-use hose.
              </blockquote>
              <blockquote>
                <em>3</em>Thread and hand tighten the blue fitting onto other end of same hose.
              </blockquote>
              <blockquote>
                <em>4</em>Ensure the system is running.
              </blockquote>
              <blockquote>
                <em>5</em>Connect the red fitting to the high-side service port.
              </blockquote>
            </div>
            <div className="col-12">
              <div className="d-block d-lg-none">
                <Step5Image />
              </div>
            </div>
            <div className="col-12">
              <div className="d-block d-lg-none">
                <Step6Image />
              </div>
              <blockquote>
                <em>6</em>Connect the blue fitting to the low-side service port.
                </blockquote>
            </div>
            <div className="col-12">
              <div className="d-block d-lg-none">
                <Step7Image />
              </div>
              <blockquote>
                <em>7</em>Press and hold red button for 7 seconds to inject product.
              </blockquote>
              <blockquote>
                <em>8</em>Once hose is empty, disconnect red fitting from system.
              </blockquote>
              <blockquote>
                <em>9</em>Remove blue fitting from system.
              </blockquote>
            </div>
            <div className="col-12">
              <div className="d-block d-lg-none">
                <Step10Image />
              </div>
              <blockquote>
                <em>10</em>Detach fittings and discard hose.
              </blockquote>
            </div>
            <div className="col-12">
              <div className="d-block d-lg-none">
                <Step11Image />
              </div>
              <blockquote>
                <em>11</em>Reassemble the fittings and store for future use.
              </blockquote>
            </div>
            <div className="col-12 col-lg-12 pt-5">
              <p className="smallest">Note: The hoses that come in the kit are designed for use with DiversiTech specified fittings.</p>
            </div>
          </div>
          {/* <Video
            videoSrcURL="https://www.youtube.com/embed/dQw4w9WgXcQ"
            videoTitle="Official Video on YouTube"
          /> */}
        </div>
        <div className="col-10 col-lg-6 offset-1 d-none d-lg-block">
          <div className="row">
            <div className="col-12 col-sm-6"><Step1Image /></div>
            <div className="col-12 col-sm-6"><Step2Image /></div>
            <div className="col-12 col-sm-6"><Step5Image /></div>
            <div className="col-12 col-sm-6"><Step6Image /></div>
            <div className="col-12 col-sm-6"><Step7Image /></div>
            <div className="col-12 col-sm-6"><Step10Image /></div>
            <div className="col-12 col-sm-6"><Step11Image /></div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default HowToUse