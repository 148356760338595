import React from "react"
import FlexBadgeIcon from "../../assets/FlexBadgeIcon.svg"


const ProductIntroFlexBadge = () => (
  <>
    <div className="gold py-5 d-flex flex-column position-relative">
      <div className="container max">
        <div className="row align-items-center">
          <div className="col-10 offset-1 col-md-5 offset-md-1 col-lg-3 offset-lg-1">
            <h1 className="alt">Connect.<br />
            Press.<br />
            Go.</h1>
          </div>
          <div className="col-7 col-sm-6 offset-1 col-md-5 offset-md-0 col-lg-4">
            <div className="mx-lg-auto" style={{ maxWidth: 300 }}>
              <FlexBadgeIcon />
            </div>
          </div>
          <div className="col-10 offset-1 col-lg-4 offset-lg-0 align-self-center mt-5 mt-md-0">
            <h3 className="mb-3">Save Time.</h3>
            <h3 className="mb-3">Make Money Fast.</h3>
            <h3 className="mb-3">Installs in seconds.</h3>
            <h3 className="mb-3">No manifold required.</h3>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default ProductIntroFlexBadge
