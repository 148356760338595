import React from 'react'
import Scrollspy from 'react-scrollspy'
import { Link } from 'gatsby'

const StickyNav = () => (
  <div className="gold sticky-menu-position triangle">
    <div className="sticky-menu-container">
      <div className="sticky-menu">
        <Scrollspy items={['section-1', 'section-2', 'section-3', 'section-4', 'section-5']} currentClassName="is-current">
          <li><Link to="#section-1">SEAL LEAKS</Link></li>
          <li><Link to="#section-2">FIND &amp; SEAL LEAKS</Link></li>
          <li><Link to="#section-3">ENHANCE PERFORMANCE</Link></li>
          <li><Link to="#section-4">REFRIGERANT RETROFIT</Link></li>
          <li><Link to="#section-5">COIL CLEANERS</Link></li>
        </Scrollspy>
      </div>
    </div>
  </div>
)

export default StickyNav;