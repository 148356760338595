import React from "react"
import PDF from '../../pdfs/MasterCare-2022-Catalog-042622_C.pdf'


const CalloutSalesSheet = () => {

  return (
    <section className="gold position-relative py-5">
      <div className="container py-5">
        <div className="row py-5">
          <div className="col-md-8 offset-md-2 text-center">
            <h2>Download Product Brochure</h2>
            <a href={PDF} className="btn--icon darkgray pdf-yellow mx-auto px-4 py-2 display" target="_blank" rel="noreferrer noopener">Download PDF</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CalloutSalesSheet
