import React from 'react'

const Card = (props, i) => {
  return (
    <div className="col-md-8 col-lg-4 mt-4 product-card">
      <div className="card-flip">
        <div className="front">
          <div className="card white h-100 border-0 rounded-0">
            <img style={{
              maxHeight: '275px',
              maxWidth: '275px',
              width: 'auto',
              margin: 'auto'
            }}
              className="card-img-top mt-4" src={props.imgSrc} alt="product" />
            <div className="card-body p-0 d-flex flex-column justify-content-center">
              <h4 className="card-title black-text mx-auto px-5 text-center">{props.cardTitleFront}</h4>
              <p className="card-text display small mx-auto" style={{
                color: 'rgba(0,0,0,0.7)'
              }}>
                {props.cardTextFront}
              </p>
              {props.children}
            </div>
            <div className="card-footer gray border-0 display text-center">
              {props.cardFooter}
            </div>
          </div>
        </div>
        <div className="back">
          <div className="card white h-100 border-0 rounded-0">
            <img style={{
              maxHeight: '275px',
              maxWidth: '275px',
              width: 'auto',
              margin: 'auto'
            }}
              className="card-img-top" src={props.imgSrcBack} alt="product detail" />
            <div className="card-body p-0 d-flex flex-column justify-content-center">
              <p className="card-text display small mx-auto" style={{
                color: 'rgba(0,0,0,0.7)'
              }}>
                {props.cardTextBack}
              </p>
              <h4 className="card-title black-text mx-auto px-5 text-center">{props.cardTitleBack}</h4>
              <a href={props.cardButtonURL} target="_blank" rel="noreferrer noopener" key={i} className={`btn--icon pdf gold mx-auto px-4 py-2 display ${props.cardClass}`}>Download PDF</a>
              {props.children}
            </div>
            <div className="card-footer gray border-0 display text-center">
              {props.cardFooter}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Card;