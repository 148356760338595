
import React from 'react'
import Card from './card'
import productImage1 from '../../images/product/985S.png'
import productImage2 from '../../images/product/985.png'
import productImage3 from '../../images/product/IAMC.png'
import productImage1B from '../../images/product/985S-MC.png'
import productImage2B from '../../images/product/985M-MC.png'
import productImage3B from '../../images/product/IA-MC.png'
import PDF1 from '../../pdfs/SDS_Mastercare_985S-MC_Leak_Armor_Flex_Shot_Cartridge_Sealant.pdf'
import PDF2 from '../../pdfs/SDS_Mastercare_985M-MC_Leak_Armor_Flex_Shot_Cartridges_Sealant.pdf'

const ProductSealLeaks = () => (
  <>
    <div id="section-1" className="anchor" />
    <section className="darkgray bkg-repeat-dark">
      <div className="container py-5">
        <div className="row py-5">
          <div className="col text-center white-text">
            <h2 className="icon icon2">SEAL LEAKS</h2>
            <p className="mx-auto"
              style={{
                maxWidth: '630px'
              }}>The proven Flex Shot formula that permanently seals and prevents future leaks, no runtime required.
              The sealant installs in seconds with our patented delivery system. Compatible with units up to 12 Tons.</p>
          </div>
        </div >
        <div className="row justify-content-center">
          <Card
            imgSrc={productImage1}
            cardTitleFront={"HVAC/R LEAK SEALANT"}
            cardTextFront={"1 HOSE & 1 SET OF FITTINGS"}
            imgSrcBack={productImage1B}
            cardTextBack={"HVAC/R LEAK SEALANT"}
            cardTitleBack={"SAFETY DATA SHEET"}
            cardButtonURL={PDF1}
            cardFooter={"985S-MC"} />
          <Card
            imgSrc={productImage2}
            cardTitleFront={"HVAC/R LEAK SEALANT"}
            cardTextFront={"4 HOSES"}
            imgSrcBack={productImage2B}
            cardTextBack={"HVAC/R LEAK SEALANT"}
            cardTitleBack={"SAFETY DATA SHEET"}
            cardButtonURL={PDF2}
            cardFooter={"985M-MC"} />
          <Card
            imgSrc={productImage3}
            cardTitleFront={"INJECT ADAPTERS"}
            cardTextFront={"1 SET OF FITTINGS"}
            imgSrcBack={productImage3B}
            cardTitleBack={"INJECT ADAPTERS"}
            cardClass={"d-none"}
            cardFooter={"IA-MC"} />
        </div>
      </div>
    </section>
  </>
)

export default ProductSealLeaks