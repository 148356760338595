import React from "react"
import NsfBadgeIcon from "../../assets/FlexBadgeIcon.svg"


const ProductIntroCoilBadge = () => (
    <>
        <div id="section-5" className="anchor" />
        <div className="gold py-5 d-flex flex-column position-relative">
            <div className="container max">
                <div className="row align-items-center">
                    <div className="col-10 offset-1 col-md-5 offset-md-1 col-lg-4 offset-lg-1">
                        <h1 className="alt">Carry Less,<br />
                            Clean More.</h1>
                    </div>
                    <div className="col-7 col-sm-6 offset-1 col-md-5 offset-md-0 col-lg-3">
                        <div className="mx-lg-auto" style={{ maxWidth: 300 }}>
                            <NsfBadgeIcon />
                        </div>
                    </div>
                    <div className="col-10 offset-1 col-lg-4 offset-lg-0 align-self-center mt-5 mt-md-0">
                        <h3 className="mb-3">We offer a wide variety of application options for coil cleaning.</h3>
                    </div>
                </div>
            </div>
        </div>
    </>
)

export default ProductIntroCoilBadge
