import React from 'react'
import Card from './card'
import productImage1 from '../../images/product/992.png'
import productImage1B from '../../images/product/992-MC.png'
import PDF from '../../pdfs/SDS_Mastercare_992-MC_Boost_Juice.pdf';

const ProductEnhancePerformance = () => (
  <>
    <div id="section-3" className="anchor" />
    <section className="black bkg-repeat bkg-repeat-light">
      <div className="container py-5">
        <div className="row py-5">
          <div className="col text-center white-text">
            <h2 className="icon icon3">Enhance Performance</h2>
            <p className="mx-auto"
              style={{
                maxWidth: '678px'
              }}>Boost Juice<sup>&reg;</sup> works to raise system performance by up to 22% while eliminating moisture and reducing compressor noise. Compatible with units up to 12 Tons.</p>
          </div>
        </div >
        <div className="row justify-content-center">
          <Card
            imgSrc={productImage1}
            cardTitleFront={"BOOST JUICE"}
            cardTextFront={"HVAC/R PERFORMANCE ENHANCER"}
            imgSrcBack={productImage1B}
            cardTextBack={"BOOST JUICE"}
            cardTitleBack={"SAFETY DATA SHEET"}
            cardButtonURL={PDF}
            cardFooter={"992-MC"} />

        </div>
      </div>
    </section>
  </>
)

export default ProductEnhancePerformance