import React from 'react'
import Card from './card'
import productImage1 from '../../images/product/995S.png'
import productImage2 from '../../images/product/995.png'
import productImage3 from '../../images/product/IAMC.png'
import productImage1B from '../../images/product/995S-MC.png'
import productImage2B from '../../images/product/995M-MC.png'
import productImage3B from '../../images/product/IA-MC.png'
import PDF1 from '../../pdfs/SDS_Mastercare_995S-MC_Leak_Armor_Flex_Shot_Cartridge_Sealant_and_Dye.pdf'
import PDF2 from '../../pdfs/SDS_Mastercare_995M-MC_Leak_Armor_Flex_Shot_Cartridges_Sealant_and_Dye.pdf'

const ProductFindSealLeaks = () => (
  <>
    <div id="section-2" className="anchor" />
    <section className="meddarkgray bkg-repeat-light">
      <div className="container py-5">
        <div className="row py-5">
          <div className="col text-center white-text">
            <h2 className="icon icon1">FIND &amp; SEAL LEAKS</h2>
            <p className="mx-auto"
              style={{
                maxWidth: '678px'
              }}>These Flex Shot products use a UV dye to quickly find leaks, and our proven formula to permanently seal them, no runtime required.
              The sealant installs in seconds with our patented delivery system. Compatible with units up to 12 Tons.</p>
          </div>
        </div >
        <div className="row justify-content-center">
          <Card
            imgSrc={productImage1}
            cardTitleFront={"HVAC/R LEAK SEALANT WITH UV DYE"}
            cardTextFront={"1 HOSE & 1 SET OF FITTINGS"}
            imgSrcBack={productImage1B}
            cardTextBack={"HVAC/R LEAK SEALANT WITH UV DYE"}
            cardTitleBack={"SAFETY DATA SHEET"}
            cardButtonURL={PDF1}
            cardFooter={"995S-MC"} />
          <Card
            imgSrc={productImage2}
            cardTitleFront={"HVAC/R LEAK SEALANT WITH UV DYE"}
            cardTextFront={"4 HOSES"}
            imgSrcBack={productImage2B}
            cardTextBack={"HVAC/R LEAK SEALANT WITH UV DYE"}
            cardTitleBack={"SAFETY DATA SHEET"}
            cardButtonURL={PDF2}
            cardFooter={"995M-MC"} />
          <Card
            imgSrc={productImage3}
            cardTitleFront={"Inject Adapters"}
            cardTextFront={"1 SET OF FITTINGS"}
            imgSrcBack={productImage3B}
            cardTitleBack={"INJECT ADAPTERS"}
            cardClass={"d-none"}
            cardFooter={"IA-MC"} />
        </div>
      </div>
    </section>
  </>
)

export default ProductFindSealLeaks
