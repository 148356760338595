import React from "react"
import HeroImage from "./heroImage"
import HeroImageBleed from "./heroImageBleed"
const HomeHero = () => (
  <>
    <HeroImageBleed />
    <div className="container max mt-5 mt-lg-0">
      <div className="row mt-5 mt-lg-0"
        style={{
          height: 'calc(-138px + 100vh)'
        }}>
        <div className="col-xl-5 d-flex flex-column justify-content-center">
          <h1 className="my-4 text-center text-xl-left">HVAC/R Solutions <br />you can trust.</h1>
          <p className="larger-rfs text-center text-xl-left mx-auto mx-xl-0"
            style={{
              maxWidth: 570
            }}>
            Whether you need to find a leak, seal a leak or boost overall system performance, MasterCare gives you the solutions to get the job done right. No manifold required, and no worrying about compatible refrigerants.

        </p>
        </div>
        <div className="col">
          <HeroImage />
        </div>
      </div>
    </div>
  </>
)

export default HomeHero
